import React from "react"
import "../global.css"
import Hero from "../components/Hero"
import {Link} from 'gatsby'
import Layout from "../components/Layout"
import Button2 from "../components/Button2"


export default () => {
    return(
        <Layout color="blanco">
            <Hero titulo="Por Un Futuro Limpio" subtitulo="Servicio de Lavandería Automática y a Domicilio" parrafo="El mejor sitio para crear aplicaciones que funcionan de verdad">
                <Link to="/contacto">Contactoss</Link>
            </Hero>
            
            <Button2>este es el boton 2</Button2>
            
        </Layout>
    )
    }
